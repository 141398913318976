export let key: string = null;

export function authorize() {
  if (!hasGpgKey()) {
    key = prompt("API key missing. Enter your key below:");
    setGpgKey(key);
  }

  key = getGpgKey();
}

export function hasGpgKey(): boolean {
  return localStorage.getItem("GITHUB_GPG_KEY") != null;
}

export function getGpgKey(): string | null {
  return localStorage.getItem("GITHUB_GPG_KEY");
}

export function setGpgKey(value: string) {
  localStorage.setItem("GITHUB_GPG_KEY", value);
}
