import * as Utils from "./utils";
import * as Store from "./store";
import * as Dom from "./dom";
import * as Constants from "./constants";
import * as Endpoints from "./endpoints";

import * as Iok from "./services/iok";
import * as Github from "./services/github";

import * as Countries from "../data/countries.json";

const conditions = {
  list: [],
  add: (data = null) => {
    let clone = Dom.templates.condition.content.cloneNode(true) as HTMLDivElement;

    let id = self.crypto.randomUUID();
    clone.firstElementChild.dataset.id = id;

    let condition = {
      id,
      name: data?.name || "",
      scope: data?.scope || "html|contains",
      values: data?.values || [],
      dom: {
        node: clone.firstElementChild,
        name: clone.querySelector("input[name='name']"),
        scopes: {
          htmlContains: clone.querySelector(".scope-option.html-contains"),
          custom: clone.querySelector(".scope-option.custom")
        },
        values: clone.querySelector("textarea[name='values']")
      },
      render: () => {
        condition.dom.name.value = condition.name;
        condition.dom.values.value = condition.values.join("\n");
        if (condition.scope.startsWith("html|contains")) {
          condition.dom.scopes.htmlContains.dataset.selected = "true";
          condition.dom.scopes.custom.dataset.selected = "false";

          condition.dom.scopes.htmlContains.textContent = condition.scope;
        } else {
          condition.dom.scopes.custom.value = condition.scope;

          condition.dom.scopes.custom.dataset.selected = "true";
          condition.dom.scopes.htmlContains.dataset.selected = "false";
        }
      }
    };

    if (data) condition.render();

    Dom.containers.conditions.append(clone);
    conditions.list.push(condition);
  },
  remove: (id) => {
    let index = conditions.list.findIndex((x) => x.id == id);
    let condition = conditions.list[index];

    conditions.list.splice(index, 1);
    condition.dom.node.remove();
  },
  get: (id) => {
    return conditions.list.find((x) => x.id == id);
  },
  load: () => {
    let json = localStorage.getItem("CONDITIONS");
    if (!json) return conditions.add();

    let data = JSON.parse(json);

    for (let condition of data) {
      conditions.add(condition);
    }
  },
  save: () => {
    localStorage.setItem("CONDITIONS", JSON.stringify(conditions.list));
  },
  delete: () => {
    localStorage.removeItem("CONDITIONS");
  }
};

let indicator = {
  id: self.crypto.randomUUID(),
  raw: null,
  pr: {
    title: null,
    body: null,
    indicatorId: null,
    indicatorValue: null
  },
  body: null,
  name: null,
  title: null,
  hash: null,
  description: null,
  references: [],
  tags: [],
  match: null,
  conditions,
  tests: {},
  dom: {
    name: document.querySelector("input[name='name']"),
    title: document.querySelector("input[name='title']"),
    description: document.querySelector("textarea[name='description']"),
    references: document.querySelector("textarea[name='references']"),
    tags: document.querySelector("textarea[name='tags']"),
    match: {
      allOfThem: document.querySelector(".match-option.all-of-them"),
      custom: document.querySelector(".match-option.custom")
    }
  }
};

const events = {
  titleInputed: () => {
    let currentTitle = indicator.dom.title.value;
    let name = currentTitle.toLowerCase().trim().replaceAll("phishing kit", "").replaceAll("  ", " ").trim().replaceAll(" ", "-");

    indicator.dom.name.value = name;

    if (!indicator.dom.title.value.includes(indicator.hash) || !indicator.dom.name.value.includes(indicator.hash.toLowerCase())) {
      indicator.hash = null;
    }
  },
  generateClicked: () => {
    let str = Utils.generateString(Constants.hashLength);

    let old = indicator.hash;
    indicator.hash = str;

    if (old) {
      indicator.dom.title.value = indicator.dom.title.value.replace(old, str);
      if (indicator.title) indicator.title = indicator.title.replace(old, str);
    } else {
      if (!indicator.dom.title.value.endsWith(" ")) str = ` ${str}`;

      indicator.dom.title.value += str;
      if (indicator.title) indicator.title += str;
    }

    events.titleInputed();
  },
  referencesChanged: () => {
    let value = indicator.dom.references.value;
    indicator.dom.references.value = value.trim();

    let lines = indicator.dom.references.value.split("\n");
    lines = lines.map((line) => {
      if (!Utils.isResult(line)) return line;

      return Utils.normalizeResultUrl(line);
    });

    lines = [...new Set(lines)];

    value = lines.join("\n");

    indicator.dom.references.value = value;
  },
  closeClicked: (sender) => {
    let node = sender.parentElement;
    let id = node.dataset.id;

    conditions.remove(id);
    conditions.save();
  },
  scopeClicked: (sender, scope) => {
    let id = sender.parentElement.parentElement.dataset.id;
    let condition = conditions.get(id);

    if (scope == "html|contains") {
      condition.dom.scopes.htmlContains.dataset.selected = "true";
      condition.dom.scopes.custom.dataset.selected = "false";
    } else if (scope == "custom") {
      sender.value = "";

      condition.dom.scopes.htmlContains.dataset.selected = "false";
      condition.dom.scopes.custom.dataset.selected = "true";
    }

    conditions.save();
  },
  valuesChanged: (sender) => {
    let id = sender.parentElement.dataset.id;
    let condition = conditions.get(id);

    condition.values = sender.value
      .split("\n")
      .map((x) => x.trim())
      .filter((x) => x && x != "");
    sender.value = condition.values.join("\n");

    let htmlContains = condition.dom.scopes.htmlContains;
    if (condition.values.length > 1) htmlContains.textContent = "html|contains|all";
    else htmlContains.textContent = "html|contains";

    condition.scope = htmlContains.textContent;
    conditions.save();
  },
  matchClicked: (sender, match) => {
    if (match == "all-of-them") {
      indicator.dom.match.allOfThem.dataset.selected = "true";
      indicator.dom.match.custom.dataset.selected = "false";
    } else if (match == "custom") {
      sender.value = "";

      indicator.dom.match.allOfThem.dataset.selected = "false";
      indicator.dom.match.custom.dataset.selected = "true";
    }
  },
  copyClicked: async () => {
    await Utils.copy(`\`\`\`yml\n${indicator.raw}\n\`\`\``);
  },
  nameChanged: (sender) => {
    let id = sender.parentElement.dataset.id;
    let condition = conditions.get(id);

    condition.name = sender.value;
  }
};

// eslint-disable-next-line no-unused-vars
function build() {
  //Data Aggregation
  indicator.name = indicator.dom.name.value.trim();
  indicator.title = indicator.dom.title.value.trim();
  indicator.description = indicator.dom.description.value.trim();
  indicator.references = indicator.dom.references.value
    .split("\n")
    .map((x) => x.trim())
    .filter((x) => x && x != "");
  indicator.tags = indicator.dom.tags.value
    .split("\n")
    .map((x) => x.trim())
    .filter((x) => x && x != "");

  for (let condition of indicator.conditions.list) {
    condition.name = condition.dom.name.value.trim();

    let isCustomSelected = condition.dom.scopes.custom.dataset.selected == "true";
    condition.scope = isCustomSelected ? condition.dom.scopes.custom.value : condition.dom.scopes.htmlContains.textContent;
  }

  let isCustomSelected = indicator.dom.match.custom.dataset.selected == "true";
  indicator.match = isCustomSelected ? indicator.dom.match.custom.value : indicator.conditions.list.map((x) => x.name).join(" and ");

  console.log("Data\n", indicator);

  //Building
  let output = "";

  output += `title: ${indicator.title}\n`;
  output += `description: |\n`;

  let descriptionLines = indicator.description.split("\n");
  for (let line of descriptionLines) {
    output += `    ${line}\n`;
  }

  output += `\n\nreferences:\n`;

  for (let reference of indicator.references) {
    output += `    - ${reference}\n`;
  }

  output += `\ndetection:\n`;

  for (let condition of indicator.conditions.list) {
    output += `\n    ${condition.name}:`;
    output += `\n      ${condition.scope}:`;

    for (let value of condition.values) {
      output += `\n        - ${value}`;
    }

    output += "\n";
  }

  output += `\n\n    condition: ${indicator.match}`;

  output += `\n\ntags:`;

  for (let tag of indicator.tags) {
    output += `\n  - ${tag}`;
  }

  console.log("Raw Indicator\n", output);

  indicator.raw = output;
  Dom.output.value = output;

  Dom.controls.copy.classList.remove("hidden");
  Dom.output.classList.remove("hidden");
  Dom.controls.groups.test.classList.remove("hidden");

  conditions.delete();
}

// eslint-disable-next-line no-unused-vars
async function test() {
  let uploaded = false;
  let scanReferences = indicator.references.filter((x) => x.includes("urlscan.io/result/"));
  if (scanReferences.length == 0) return alert("No Urlscan references");

  while (Dom.tests.firstChild) {
    Dom.tests.firstChild.remove();
  }

  for (let reference of scanReferences) {
    indicator.tests[reference] = false;

    let uuid = Utils.parseResultUrl(reference);

    let li = document.createElement("li");
    Dom.tests.append(li);

    if (!uploaded) {
      li.textContent = `${reference} - Uploading`;
      let result = await Iok.upload(indicator.name, indicator.raw);

      if (result != "Accepted") {
        return (li.textContent = `${reference} - N/A`);
      } else uploaded = true;
    }

    li.textContent = `${reference} - Matching`;
    let result = await Iok.match(uuid);

    let data;
    try {
      data = JSON.parse(result);
    } catch (err) {
      console.error(err);
      return (li.textContent = `${reference} - Failed to parse JSON`);
    }

    let matched = data.some((x) => x.ID == indicator.name);
    if (matched) {
      indicator.tests[reference] = true;
    }

    li.textContent = `${reference} - ${matched ? "Matched" : "Didn't match"}`;
  }

  let output = assemble(scanReferences);

  indicator.body = output;
  console.log("PR Body\n", output);

  indicator.pr.title = `Create indicator: ${indicator.title}`;
  indicator.pr.body = output;
  indicator.pr.indicatorId = indicator.name;
  indicator.pr.indicatorValue = indicator.raw;

  Dom.controls.submit.classList.remove("hidden");
}

function assemble(scanReferences) {
  let emoji;
  let targetCountryIndicator = indicator.tags.find((x) => x.startsWith("target_country"));
  if (targetCountryIndicator) {
    let countryName = targetCountryIndicator.split(".")[1];
    let countryCode = Countries[countryName];
    if (countryCode) {
      emoji = Utils.getEmoji(countryCode);
    }
  }

  let output = "";

  console.log("Tests", indicator.tests);
  let succeeded = Object.values(indicator.tests).filter((x) => x == true).length;

  //PR Body Building
  output += `🎣 **Indicator of Kit PR through IOK Builder**\n`;
  output += `\n`;
  output += "- **Tests:**\n";
  output += `✅ Indicator matches **\`${succeeded}\`**/**\`${scanReferences.length}\`** referenced Urlscan results.\n`;
  output += `\n`;
  output += `- **Tags**: ${indicator.tags.map((tag) => `\`${tag}\``).join(", ")}${emoji ? ` (${emoji})` : ""}\n`;
  output += `- **Name:**\n`;
  output += `\`${indicator.name}\` - \`${indicator.title}\`\n`;
  output += `- **Description:**\n`;
  output += "```\n";
  output += `${indicator.description}\n`;
  output += "```\n";
  output += `- **References:** (\`${indicator.references.length}\`)\n`;

  output += `${indicator.references.join("\n")}\n`;

  output += `- **Screenshot:**\n`;

  let firstReference = scanReferences[0];
  output += `<img src="https://urlscan.io/screenshots/${Utils.parseResultUrl(firstReference)}.png" width="800" height="600" />`;

  return output;
}

// eslint-disable-next-line no-unused-vars
async function submit() {
  Dom.controls.submit.textContent = "Submitting...";

  let pr;
  try {
    let str = await Github.submit(indicator.pr);
    pr = JSON.parse(str);
  } catch (err) {
    Dom.controls.submit.textContent = "Error";
    console.error(err);
    return;
  }

  Dom.controls.submit.textContent = `Submitted: ${pr.number}`;
  let url = `${Endpoints.repository}/pull/${pr.number}`;
  Utils.copy(url);

  conditions.delete();
}

conditions.load();

async function main() {
  registerGlobals();
  Store.authorize();
  conditions.load();

  pingServices();
  setInterval(pingServices, Constants.pingInterval);
}

async function pingServices() {
  await Iok.connect();
  await Github.connect();
}

declare global {
  interface Window {
    Dom: unknown;
    Store: unknown;
    conditions: unknown;
    indicator: unknown;
    events: unknown;
    build: unknown;
    test: unknown;
    assemble: unknown;
    submit: unknown;
  }
}

function registerGlobals() {
  window.Dom = Dom;
  window.Store = Store;

  window.conditions = conditions;
  window.indicator = indicator;
  window.events = events;
  window.build = build;
  window.test = test;
  window.assemble = assemble;
  window.submit = submit;
}

main();

