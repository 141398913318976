import { ApiClient } from "./client";
import * as Endpoints from "../endpoints";
import * as Dom from "../dom";
import { key } from "../store";

let client = new ApiClient(Endpoints.github);

export async function connect() {
  try {
    let res = await client.request("/");
    Dom.statuses.github.textContent = `✅ ${res.status}`;
  } catch (err) {
    Dom.statuses.github.textContent = err.toString();
  }
}

export async function submit(data: unknown) {
  let res = await client.request("/submit/iok", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: key
    },
    credentials: "include"
  });

  return await res.text();
}
