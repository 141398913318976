export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const lists = {
  lowercase: Array.from("abcdefghijklmnopqrstuvwxyz"),
  uppercase: Array.from("ABCDEFGHIJKLMNOPQRSTUVWXYZ"),
  numbers: Array.from("0123456789")
};

export const charset = [...lists.lowercase, ...lists.uppercase, ...lists.numbers];

export function next(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min);
}

export function generateString(length: number) {
  let result = [];

  for (let i = 0; i < length; i++) {
    let index = next(0, charset.length);
    let char = charset[index];
    result.push(char);
  }

  return result.join("");
}

export async function download(path: string) {
  let res = await fetch(path);
  if (res.status != 200) return alert(`[Downloader] Failed to download '${path}'`);

  return await res.json();
}

export function getEmoji(countryCode: string) {
  let codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

export async function copy(text: string) {
  if (!navigator.clipboard) return alert("Clipboard is unsupported or forbidden.");
  await navigator.clipboard.writeText(text);
}

export function isResult(text: string) {
  return text.startsWith("https://urlscan.io/result/");
}

export function parseResultUrl(url: string) {
  let parts = url.split("/");
  return parts[4];
}

export function normalizeResultUrl(url: string) {
  let uuid = parseResultUrl(url);
  return `https://urlscan.io/result/${uuid}/`;
}
