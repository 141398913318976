export const output = document.querySelector("textarea.output") as HTMLTextAreaElement;
export const tests = document.querySelector(".tests") as HTMLDivElement;
export const statuses = {
  iok: document.querySelector(".status.api-connection") as HTMLSpanElement,
  github: document.querySelector(".status.github-connection") as HTMLSpanElement
};
export const containers = {
  conditions: document.querySelector(".container.conditions") as HTMLDivElement
};
export const templates = {
  condition: document.querySelector("template[name='condition']") as HTMLTemplateElement
};
export const controls = {
  generate: document.querySelector(".control.generate") as HTMLButtonElement,
  build: document.querySelector(".control.build") as HTMLButtonElement,
  copy: document.querySelector(".control.copy") as HTMLButtonElement,
  test: document.querySelector(".control.test") as HTMLButtonElement,
  submit: document.querySelector(".control.submit") as HTMLButtonElement,
  add: document.querySelector(".control.add-condition") as HTMLButtonElement,
  groups: {
    test: document.querySelector(".control.group.test") as HTMLDivElement,
    build: document.querySelector(".control.group.build") as HTMLDivElement
  }
};
