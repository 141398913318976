import { ApiClient } from "./client";
import * as Endpoints from "../endpoints";
import * as Dom from "../dom";

let client = new ApiClient(Endpoints.iok);

export async function connect() {
  try {
    let res = await client.request("/");
    Dom.statuses.iok.textContent = `✅ ${res.status}`;
  } catch (err) {
    Dom.statuses.iok.textContent = err.toString();
  }
}

export async function upload(id: string, content: string) {
  let res = await client.request(`/api/upload?id=${id}`, {
    method: "POST",
    body: content
  });

  return await res.text();
}

export async function match(uuid: string) {
  let res = await client.request(`/api/match?uuid=${uuid}`);

  return await res.text();
}
