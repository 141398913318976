import { maxRetries, retryDelay } from "../constants";
import * as Utils from "../utils";

export class ApiClient {
  base: string;

  constructor(base: string) {
    this.base = base;
  }

  async request(path: string, init?: RequestInit) {
    let retries = 0;

    let res;
    let error;

    while (retries < maxRetries) {
      try {
        res = await fetch(`${this.base}${path}`, init);

        if (res.status <= 300) return res;
      } catch (err) {
        error = err;
      }

      retries++;
      await Utils.sleep(retryDelay);
    }

    if (retries >= maxRetries) throw `Failed to request in ${maxRetries} retries${` - ${error}` || ""}`;

    return res;
  }
}
